@import url("https://fonts.googleapis.com/css2?family=Lato:wght@100;300;400;700;900&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

:root {
  --primary: #104ceb !important;
  --secondary: #3e4954;
  --primary-hover: #104aeb4e !important;
  --light-color: #f4f6fd;
}

.bgl-primary {
  background-color: var(--light-color) !important;
}
[data-sidebar-style="full"][data-layout="vertical"]
  .deznav
  .metismenu
  > li.mm-active
  > a {
  background-color: var(--light-color);
}
.logo-abbr {
  background-color: blue;
  border-radius: 8px;
  padding: 3px;
}
.brand-title {
  font-weight: 600;
  font-family: "Poppins", serif;
}
[data-sidebar-style="full"][data-layout="vertical"]
  .deznav
  .metismenu
  > li
  > a:hover {
  color: var(--primary) !important;
}
[data-sidebar-style="full"][data-layout="vertical"]
  .menu-toggle
  .nav-header
  .nav-control
  .hamburger
  .line {
  background-color: #104ceb !important;
}

.display-flex {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.gap {
  gap: 10px;
}
.hover-item-transition {
  transition: all 0.3s;
}
.hover-item-transition:hover {
  transform: scale(1.1);
}

.btn-primary {
  background-color: #104ceb !important;
  border: none !important;
}
.btn-primary:hover {
  background-color: var(--primary-hover);
}
.login-form-bx .box-skew1:after {
  background-color: var(--primary) !important;

  background-image: none !important;
}
.login-form-bx .inner-content {
  padding-left: 8rem !important;
}
.popup-warning-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  border: 2px solid orange;
  color: orange;
  width: 60px;
  height: 60px;
  line-height: 26px;
  font-size: 26px;
  font-weight: bold;
  margin-bottom: 1.5rem;
}
.table-scale {
  transition: all 0.3s;
}
.table-scale:hover {
  cursor: pointer;
  transform: scale(1.02);
}
.switch-disable {
  margin: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}
.range-picker-input {
  border-radius: 0.75rem !important;
}
.picker .picker-input__text {
  border-radius: 0.75rem !important;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  border: none !important;
  padding: 1rem 1.25rem !important;
}
.picker__container {
  left: unset !important;
  right: 15px !important;
}
.filter-tabs {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  background-color: #fad5d5;
  padding: 5px;
  border-radius: 8px;
  flex-wrap: nowrap;
}
.tab {
  margin: 0;
  color: #104ceb;
  font-weight: 500;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out;
  width: 90px;
  text-align: center;
  cursor: pointer;
}
.active-tab {
  background-color: #fff;
  padding: 8px 12px;
  border-radius: 6px;
  font-weight: 500;
  color: #000;
  margin: 0;
  width: 90px;
  text-align: center;
  transition: color 0.3s ease-in-out, background-color 0.5s ease-in-out,
    border-color 0.5s ease-in-out;
  /* transform: translateX(0.1s); */
}
.disabled-row {
  background-color: #e9ecef !important; /* Light gray background */
  color: #7d6f6c !important; /* Gray text */
  pointer-events: none !important; /* Disable clicking */
  opacity: 0.65 !important; /* Slightly fade the row */
}

.hover-table {
  transition: all 0.3s;
  color: #9b4444;
}
/* .hover-table:hover {
  transform: scale(1.2);
  cursor: pointer;
} */
.dropdown-menu.show:hover {
  z-index: 1;
}
.password-input {
  position: relative;
}

.password-icon {
  position: absolute;
  right: 20px;
  top: 50%;
  transform: translateY(-50%);
  color: #555; /* Adjust the color as needed */
  cursor: pointer;
}
.group-modal .modal-dialog {
  max-width: 360px !important;
}

.group-modal .card {
  margin-bottom: 0px !important;
  padding: 15px !important;
}
.group-modal .close {
  top: 2px !important;
  font-size: 2rem !important;
  right: 12px !important;
}
.group-modal .modal-dialog-centered {
  min-height: 100% !important;
  height: 100% !important;
}

.group-name {
  font-size: 1.2rem;
  margin-left: 10px;
}
.checkbox-container {
  display: flex;
  align-items: center;
  margin-bottom: 8px;
  padding-left: 0;
}

/* Hide default checkbox */
.checkbox-container input {
  display: none;
}

/* Style the custom checkbox */
.checkbox-container .checkmark {
  width: 1.3rem;
  height: 1.3rem;
  border: 1px solid #ccc;
  border-radius: 5px;
  display: inline-block;
  position: relative;
  margin-top: 6px;
  /* margin-right: 8px; */
}

/* Style the checkmark when the checkbox is checked */
.checkbox-container input:checked + .checkmark {
  background-color: var(--primary);
}

/* Create the checkmark inside the checkbox */
.checkbox-container .checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when the checkbox is checked */
.checkbox-container input:checked + .checkmark:after {
  display: block;
}

/* Style the checkmark itself */
.checkbox-container .checkmark:after {
  left: 7px;
  top: 2.5px;
  width: 5px;
  height: 13px;
  border: solid white;
  border-width: 0 2px 2px 0;
  /* border-radius: 5px; */
  transform: rotate(45deg);
}

.join-button {
  text-align: center;
  width: 90%;
  height: 40px;
  padding: 8px;
  background-color: var(--primary);
  color: #fff;
  border: none;
  border-radius: 25px;
  box-shadow: 3px 3px 3px #b1b1b1, -3px -3px 3px #fff;
  transition: all 0.3s ease-in-out;
}
.join-button:hover {
  transform: scale(0.98);
  background-color: rgb(44, 99, 251);
}

.search-member {
  box-shadow: rgba(0, 0, 0, 0.24) 0px 1px 6px;
  border: none;
  border-radius: 5px;
  width: 75%;
}
.hide-scrollbar {
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* Internet Explorer and Microsoft Edge */
}

/* For Webkit browsers like Chrome and Safari, you can use the following */
.hide-scrollbar::-webkit-scrollbar {
  display: none;
}
