.user-details {
  display: flex;
  flex-direction: column;
  /* background: #fff; */
  padding: 2rem;
  padding-left: 4rem;
  padding-top: 4rem;
  margin: 0 auto;
  border-radius: 8px;
  font-family: Arial, sans-serif;
}

.user-header {
  display: flex;
  align-items: start;
  border-bottom: 1px solid #ddd;
  padding-bottom: 20px;
  gap: 4rem;
}

.user-image {
  border-radius: 5px;
  width: 240px;
  height: 240px;
  object-fit: cover;
}

.user-info {
  flex-grow: 1;
}

.user-info h2 {
  margin: 0;
  font-size: 24px;
}

.user-job-title {
  color: #007bff;
  margin: 5px 0;
}

.user-location {
  color: #999;
}

.user-rating {
  display: flex;
  align-items: center;
  margin-top: 10px;
}

.stars {
  margin-left: 10px;
  color: gold;
}

.user-actions {
  margin-top: 10px;
}
.booking-profile-img {
  height: 150px;
  width: 150px;
  object-fit: cover;
}
.user-content {
  display: flex;
  flex-direction: column;
  margin-top: 20px;
}
.user-contact h5 {
  font-weight: 600;
  color: rgb(172, 166, 166) !important;
  margin-top: 2rem;
}
.user-contact p {
  display: flex;
  gap: 1rem;
  align-items: start;
  margin-top: 0.5rem;
  justify-content: flex-start;
}
.user-contact strong:first-child {
  width: 150px;
}
.user-contact strong:last-child {
  color: #007bff;
}

.document-section h5 {
  font-weight: 600;
  color: rgb(172, 166, 166) !important;
  margin-top: 2rem;
}
.documents-images {
  border-bottom: 1px solid #b1abab;
  display: flex;
  gap: 10px;
}
.documents-images img {
  width: 150px;
  height: 150px;
  object-fit: cover;
  border-radius: 5px;
  padding-bottom: 5px;
}

.agency-profile {
  padding-top: 2rem;
  max-width: 250px;
}

.agency-profile h5 {
  border-bottom: 1px solid #ddd;
  margin: 0;
  color: #b1abab;
  font-weight: 600;
}
.work-item {
  padding-top: 0.5rem;
}
.work-item p {
  margin: 0;
}

.user-services ul {
  list-style: none;
  padding: 0;
}

.user-services li {
  padding: 5px 0;
}
.profile-tabs {
  display: flex;
  justify-content: start;
  align-items: center;
  border-bottom: 1px solid #ddd;
  gap: 2rem;
}
.profile-tabs p {
  margin: 0;
  font-weight: 600;
}

.tabs {
  display: flex;
  border-bottom: 2px solid #ddd;
  /* margin-bottom: 20px; */
}

.tab-button {
  background: none;
  border: none;
  border-bottom: 2px solid transparent;
  font-size: 16px;
  transition: all 0.3s;
  cursor: pointer;
  text-align: center;
  min-width: 80px;
}
.tab-button.active {
  border-bottom: 2px solid #8e1012;
  font-weight: bold;
  transform: translate(0.3s);
  border-radius: 8px 8px 0px 0px;
  cursor: default;
  min-width: 80px;

  text-align: center;
}
.user-contact-civilian {
  width: 100%;
  padding: 1rem;
}
.user-contact-civilian h5 {
  font-weight: 600;
  color: black !important;
}
.section-first {
  /* border: 1px solid var(--primary);
  border-radius: 5px;
  background-color: var(--light-color); */
  padding: 0.2rem 0.2rem 0.2rem 0rem;
  border-top: 1px solid #ddd;
}
.section-first p {
  margin: 0;
}
.service-badge {
  padding: 0.1rem 0.5rem 0.1rem 0.5rem;
  border: 1px solid lightgray;
  border-radius: 20px;
  background-color: lightgray;
  font-weight: 600;
  color: #000;
  font-size: 14px;
}

@media (min-width: 768px) {
  .user-details {
    flex-direction: row;
    flex-wrap: wrap;
  }

  .user-header {
    flex: 1 1 100%;
    padding-bottom: 20px;
    border-bottom: none;
    justify-content: space-between;
  }

  .user-content {
    flex: 1 1 100%;
    display: flex;
    flex-wrap: wrap;
    margin-top: 0;
  }

  .user-work,
  .user-services,
  .user-contact,
  .user-basic-info {
    flex: 1 1 45%;
    margin-right: 20px;
  }

  .user-work,
  .user-services {
    flex: 1 1 100%;
  }

  .user-content > div:nth-child(2n) {
    margin-right: 0;
  }
}
